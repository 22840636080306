<template>
  <div class="my-card">
    <div class="title-wrap">
      <div class="title">{{ title || "卡片标题" }}</div>
      <!-- 右侧卡槽 -->
      <slot name="right"></slot>
    </div>
    <van-divider />
    <slot></slot>
  </div>
</template>

<script>
import Vue from 'vue';
import { Divider } from 'vant';

Vue.use(Divider);
export default {
  name: 'MyCard',
  props: {
    title: {
      type: String,
      require: true,
    }
  }
}

</script>

<style lang="scss" scoped>
.my-card{
  margin: 10px 8px;
  padding: 14px 18px;
  border-radius: 10px;
  background-color: #fff;
  .title-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
      position: relative;
      font-weight: 600;
      padding-left: 16px;
      &::before{
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        height: 12px;
        width: 20px;
        margin-right: 10px;
        background-image: url('../../assets/titleIcon.png');
        background-size: 50%;
        background-repeat: no-repeat;
        transform: translateY(-50%);
      }
    }
  }
}
</style>